
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import axios from 'axios'
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    let login = ref('')
    let mdp = ref('')
    let msgErr = ref('')
    let codeEnt = ref('')
    let enCharge = ref(false)

    const { cookies } = useCookies();

    const connect = () => {
      msgErr.value = ""
      enCharge.value = true
      axios({
        method: "get",
        url: store.state.server_url + "/checkUser",
        params: {
          login: login.value,
          mdp: mdp.value,
          codeEnt: codeEnt.value,
        }
      })
        .then((response) => {
          console.log(response)
          if (response.data.user === 'YES') {
            console.log(response);
            cookies.set('AtomProgress_JWT',response.data.token,7200)
            cookies.set('jwtAtomProgress_codeEnt',codeEnt.value,7200)
            cookies.set('jwtAtomProgress_UserId',response.data.id,7200)
            cookies.set('jwtAtomProgress_UserAdm',response.data.adm,7200)
            cookies.set('jwtAtomProgress_UserPseudo',login.value,7200)
            setTimeout(() => {
              router.push("/home");
            }, 1000);
            
          } else {
            console.log(response.data.user)
            switch (response.data.user) {

              case "noDate":
                msgErr.value = "Date d'utilisation pour l'entreprise " + codeEnt.value + " dépassée !";
                break
              case "CodeEnt":
                msgErr.value = "L'entreprise " + codeEnt.value + " n'éxiste pas !";
                break
              default:
                msgErr.value = "Login ou mot de passe invalide !";
            }
            
          }
        })
        .catch((err) => {
            
                msgErr.value = "Login ou mot de passe invalide !";
            console.log(err);
        });
      //
    };

    return {
        connect,
        login,
        mdp,
        msgErr,
        codeEnt,
        enCharge,
    }

  },
});
