<template>
  <router-view />
</template>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;600&family=Pacifico&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Nunito","Calibri", "Courier New", Courier, monospace;
  box-sizing: border-box;
}

body {
  /*background-color: #0f0f0f;*/
  background-image: url('/assets/fond/smoke.jpg');
  background-size: cover;
}

.erreur {
  width: 70%;
  border: 1px solid red;
  color: red;
  background-color: rgb(255, 170, 170);
  padding: 0.5em 2em;
  text-align: center;
  font-weight: 500;
  margin: 12px auto;
}

.success {
  width: 70%;
  border: 1px solid green;
  color: green;
  background-color: rgb(171, 238, 171);
  padding: 0.5em 2em;
  text-align: center;
  font-weight: 500;
  margin: 12px auto;
}

.warning {
  width: 70%;
  border: 1px solid orange;
  color: rgb(65, 42, 0);
  background-color: rgb(255, 221, 157);
  padding: 0.5em 2em;
  text-align: center;
  font-weight: 500;
  margin: 12px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titre {
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #0f0f0f;
  font-weight: 600;
  margin: 10px 0;
}

.titre_petit {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: #0f0f0f;
  font-weight: 500;
  margin: 10px 0;
}

button {
  border: 1px solid #0f0f0f;
  color: #f0f0f0;
  background-color: #0f0f0f;
  padding: 0.3em 1em;
  transition: 0.2s ease;
  cursor: pointer;
  margin: 5px 2px 6px 2px;

  &:hover {
    color: #0f0f0f;
    background-color: #f0f0f0;
    box-shadow: 3px 3px 8px cyan;
  }
}

.btnBlanc {
  border: 1px solid #f0f0f0;
  color: #0f0f0f;
  background-color: #f0f0f0;
  padding: 0.3em 1em;
  transition: 0.2s ease;
  cursor: pointer;
  margin: 5px 0 3px 0;

  &:hover {
    color: #f0f0f0;
    background-color: #0f0f0f;
    box-shadow: 3px 3px 8px cyan;
  }
}

.btnGrand {
  border: 1px solid #0f0f0f;
  color: #f0f0f0;
  background-color: #0f0f0f;
  padding: 0.3em 1em;
  transition: 0.2s ease;
  cursor: pointer;
  margin: 5px 0 3px 0;
  width: 80%;

  &:hover {
    color: #0f0f0f;
    background-color: #f0f0f0;
    box-shadow: 3px 3px 8px cyan;
  }
}

.btnPetit {
  border: 1px solid #0f0f0f;
  color: #f0f0f0;
  background-color: #0f0f0f;
  padding: 0.3em 1em;
  transition: 0.2s ease;
  cursor: pointer;
  margin: 5px 3px 3px 0;

  &:hover {
    color: #0f0f0f;
    background-color: #f0f0f0;
    box-shadow: 3px 3px 8px cyan;
  }
}

.btn {
  margin: 0 5px 0 0;
  width: 28px;
  height: 28px;
  border: 1px gray solid;
  cursor: pointer;
  background-color: transparent;
  img {
    width: 28px;
    height: 28px;
  }
  &:hover {
    scale: 1.1;
    box-shadow: 3px 3px 8px cyan;
  }
}
.icones {
  width: 100%;
  white-space: pre;
  overflow: hidden;
  text-align: center;
  padding: 0.3em 2px;
  height: 5em;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.choix {
  max-width: 1400px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #f0f0f0;
    margin: 0 10px 0 0;
  }
  select {
    margin: 0 10px 0 0;
  }
  label {
    color: #f0f0f0;
    margin: 0 5px 0 0;
  }
  input {
    padding: 5px;
    margin: 0 5px 0 0;
  }
}

select {
  padding: 0.3em 1.5em 0.3em 0.5em;
  border: 1px solid gray;
}

body {
  background-color: #0f0f0f;
}

.erreur {
  width: 70%;
  border: 1px solid red;
  color: red;
  background-color: rgb(255, 170, 170);
  padding: 0.5em 2em;
  text-align: center;
  font-weight: 500;
  margin: 12px auto;
}

.success {
  width: 70%;
  border: 1px solid green;
  color: green;
  background-color: rgb(171, 238, 171);
  padding: 0.5em 2em;
  text-align: center;
  font-weight: 500;
  margin: 12px auto;
}

.warning {
  width: 70%;
  border: 1px solid orange;
  color: rgb(65, 42, 0);
  background-color: rgb(255, 221, 157);
  padding: 0.5em 2em;
  text-align: center;
  font-weight: 500;
  margin: 12px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titre {
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #0f0f0f;
  font-weight: 600;
  margin: 10px 0;
}

.titre_petit {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: #0f0f0f;
  font-weight: 500;
  margin: 10px 0;
}

button {
  border: 1px solid #0f0f0f;
  color: #f0f0f0;
  background-color: #0f0f0f;
  padding: 0.3em 1em;
  transition: 0.2s ease;
  cursor: pointer;
  margin: 10px 0 3px 0;

  &:hover {
    color: #0f0f0f;
    background-color: #f0f0f0;
  }
}

.btn {
  margin: 0 5px 0 0;
  width: 40%;
  height: 40%;
  max-width: 30px;
  max-height: 30px;
  min-width: 15px;
  min-height: 15px;
  border: 1px gray solid;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 85%;
    height: 85%;
  }
  &:hover {
    scale: 1.1;
  }
}

.btnSaisie {
  margin: 0 5px 0 0;
  width: 30px;
  height: 30px;
  border: 1px gray solid;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
  }
  &:hover {
    scale: 1.1;
  }
}
.icones {
  width: 100%;
  white-space: pre;
  overflow: hidden;
  text-align: center;
  padding: 0.3em 2px;
  height: 5em;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.enLigneCenter {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 3px 0;
}
</style>
