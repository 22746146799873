import { createStore } from 'vuex'

export default createStore({
  state: {
    //server_url: "http://localhost:5000",
    server_url: "http://13.38.82.232:5000",
    current_project: '',
    finish: 'no',
    apercuProj: ['','',{}],
    retourListe: 'liste',
    infoSuivi: {},
    idArboProject: "",
    idToDoProject: "",
  },
  mutations: {
     changeCurrentProject(state,newId: string) {
      state.current_project = newId
    },
    changeFinish(state,newFinish: string) {
      state.finish = newFinish
    },
    changeApercuProj(state, newVal: string[]) {
      state.apercuProj = newVal
    },
    changeRetourListe(state, retour: string) {
      state.retourListe = retour
    },
    changeInfoSuivi(state, newVal: any) {
      state.infoSuivi = newVal
    },
    change_IdArboProject(state, newId: string) {
      state.idArboProject = newId
    },
    change_IdTodoProject(state, newId: string) {
      state.idToDoProject = newId
    }
  },
  actions: {
    
  },
  modules: {
    
  },
  
})
